import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Form from '../../../components/Forms/Form'
import FormLabel from '../../../components/Forms/FormLabel'
import FormInput from '../../../components/Forms/FormInput'
import FormInputPassword from '../../../components/Forms/FormInputPassword'
import FormInputErrorMessage from '../../../components/Forms/FormInputErrorMessage'
import FormSubmit from '../../../components/Forms/FormSubmit'
import FormErrorMessage from '../../../components/Forms/FormErrorMessage'
import ROUTES from '../../../utils/ROUTES'
import { AppContext } from '../../../store/context'
import ConnectionLayout from '../../../layouts/login/ConnectionLayout'
import Loading from '../../../components/Loaders/Loading'
import PopUpInfo from '../../../layouts/PopUpInfo'
import Instructions from '../../../components/molecules/Email/Instructions'
import { logout, modifyEmail } from '../../../services/connexion-services'

interface Props {
  external: boolean
  connected?: boolean
}

const ModifyUserEmail = ({ external, connected }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [errorMsg, setErrorMsg] = useState('')
  const { state } = React.useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('')
  let timer: NodeJS.Timeout

  if (!external) history.push(ROUTES.DeniedAccess)

  const userForm = {
    password: '',
    email: '',
    confirmEmail: '',
  }

  useEffect(() => {
    return () => clearTimeout(timer)
  })

  const handleModal = () => {
    setShowModal(true)
    timer = setTimeout(() => {
      setShowModal(false)
      history.push(ROUTES.CheckEmail)
    }, 10000)
  }

  return (
    <>
      <ConnectionLayout
        heading={t('ModifyUserEmail.title.pageTitle')}
        topAdvice={t('ModifyUserEmail.title.infoTitle')}
        supplementaryAdvice={t('ModifyUserEmail.title.supplementaryInfoTitle')}
        connected={connected}
      >
        <div className="modify-email-info">
          <p>{t('ModifyUserEmail.title.subInfo.part1')}</p>
          <p className="font-bold">
            {t('ModifyUserEmail.title.subInfo.part2')}
          </p>
        </div>
        <div className="modify-user-email">
          <Formik
            initialValues={userForm}
            validationSchema={Yup.object({
              password: Yup.string().required(
                t('ModifyUserEmail.error.mandatory')
              ),
              email: Yup.string()
                .email(t('ModifyUserEmail.error.email.invalid'))
                .required(t('ModifyUserEmail.error.mandatory'))
                .transform((value) => value.toLowerCase()),
              confirmEmail: Yup.string()
                .oneOf(
                  [Yup.ref('email')],
                  t('ModifyUserEmail.error.email.notEqual')
                )
                .email(t('ModifyUserEmail.error.email.invalid'))
                .required(t('ModifyUserEmail.error.mandatory'))
                .transform((value) => value.toLowerCase()),
            })}
            onSubmit={async (values) => {
              /* eslint-disable-next-line no-param-reassign */
              values.email = values?.email.toLowerCase()
              try {
                setIsLoading(true)
                await modifyEmail(
                  state?.user?.email,
                  values.email,
                  values.password
                ).then((res) => {
                  if (res === 200) {
                    logout()
                    setEmail(values.email)
                    handleModal()
                  } else if (res === 400) {
                    setErrorMsg(t('ModifyUserEmail.error.email.exist'))
                  } else {
                    setErrorMsg(t('ModifyUserEmail.error.failed'))
                  }
                  setIsLoading(false)
                })
              } catch (e) {
                setErrorMsg(t('ModifyUserEmail.error.failed'))
                setIsLoading(false)
              }
            }}
          >
            {({ isValid, dirty }) => (
              <Form>
                <FormErrorMessage msg={errorMsg} />
                <span>{isValid}</span>
                <div className="modify-user-email__input">
                  <FormLabel htmlFor="password">
                    {t('ModifyUserEmail.label.password')}
                  </FormLabel>
                  <FormInputPassword
                    name="password"
                    placeholder={t('ModifyUserEmail.placeholder.password')}
                  />
                  <FormInputErrorMessage name="password" />
                </div>
                <div className="modify-user-email__input ">
                  <FormLabel htmlFor="email">
                    {t('ModifyUserEmail.label.email')}
                  </FormLabel>
                  <FormInput
                    name="email"
                    type="email"
                    placeholder={t('ModifyUserEmail.placeholder.email')}
                  />
                  <FormInputErrorMessage name="email" />
                </div>
                <div className="modify-user-email__input ">
                  <FormLabel htmlFor="confirmEmail">
                    {t('ModifyUserEmail.label.confirmEmail')}
                  </FormLabel>
                  <FormInput
                    name="confirmEmail"
                    type="email"
                    placeholder={t('ModifyUserEmail.placeholder.confirmEmail')}
                    allowPast={false}
                  />
                  <FormInputErrorMessage name="confirmEmail" />
                </div>

                <div className="modify-user-email__footer">
                  <div className="documents__cgu-block modify-user-email__cgu-block">
                    <FormSubmit disabled={!(isValid && dirty) || isLoading}>
                      <Loading isLoading={isLoading} inButton>
                        {t('ModifyUserEmail.button')}
                      </Loading>
                    </FormSubmit>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ConnectionLayout>
      <PopUpInfo
        type="info"
        title={t('ModifyUserEmail.modal.title')}
        body={`${t('ModifyUserEmail.modal.body.part1')}${email}${t(
          'ModifyUserEmail.modal.body.part2'
        )}`}
        show={showModal}
        size="lg"
        backdrop="static"
        onHideShow={() => history.push(ROUTES.CheckEmail)}
      >
        <Instructions />
      </PopUpInfo>
    </>
  )
}

ModifyUserEmail.defaultProps = {
  connected: true,
}

export default ModifyUserEmail
