/* eslint-disable class-methods-use-this */
import { toEuro } from '../utils/amount-utils'
import { formatDate, parseDate } from '../utils/date-utils'
import {
  DocumentWDHTableAPI,
  DocumentWDHTableEntity,
} from '../utils/DocumentTableAPI'
import EnumTypeDocumentWdh from '../utils/enums/MyDocuments/TypeDocumentWdh'
import { normalizeString } from '../utils/ts-utils'

class DocumentWDHTableNormalizer {
  normalize(tableDocument: DocumentWDHTableAPI[]): DocumentWDHTableEntity[] {
    return tableDocument.map((item) => {
      // Concat address
      const address = `${normalizeString(item.raisonSociale)}
      ${normalizeString(item.rueAdresse)} ${normalizeString(
        item.codePostalAdresse
      )} ${normalizeString(item.villeAdresse)}`

      const billingTypes =
        item.typeDocument !== (EnumTypeDocumentWdh.BI || EnumTypeDocumentWdh.BP)

      return {
        id: item.idDocument,
        typeDocument: item.typeDocument,
        numDocument: item.numDocument,
        anneeMois: item.anneeMois
          ? formatDate(parseDate(item.anneeMois), 'MM/YYYY')
          : undefined,
        dateCreation: item.dateCreation
          ? formatDate(parseDate(item.dateCreation), 'DD/MM/YYYY')
          : undefined,
        montant: billingTypes ? toEuro(item.montantTotalHT ?? 0) : '',
        etablissement: address,
        gedId: item.gedId,
        codeDossierERP: item.codeDossierERP ?? '',
      }
    })
  }
}

export default new DocumentWDHTableNormalizer()
