/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react'

import Select, {
  GroupedOptionsType,
  OptionsType,
  components,
} from 'react-select'
import SvgIcon from '../Icons/SvgIcon'

interface Props {
  id?: string
  defaultOptions?: string
  options:
    | OptionsType<{ label: string; value: string }>
    | GroupedOptionsType<{ label: string; value: string }>
    | undefined
  value?: any
  className?: string
  onChange?: any
  name?: string
  isDisabled?: boolean
  height?: number
  width?: number | string
  placeholder?: string
  isSearchable?: boolean
  isCentred?: boolean
  withCustomChevron?: boolean
  optionsDisplayStyle?: { [key: string]: string | Record<string, unknown> }
  menuDisplayStyle?: { [key: string]: string }
  menuListStyle?: { [key: string]: string }
  placeholderStyle?: { [key: string]: string }
  valueContainerStyle?: { [key: string]: string }
  controlStyle?: { [key: string]: string | Record<string, unknown> }
  indicatorsContainerStyle?: { [key: string]: string | Record<string, unknown> }
  singleValueStyle?: { [key: string]: string }
}

const CustomSelect: React.FC<Props> = ({
  id,
  className,
  options,
  defaultOptions,
  value,
  onChange,
  name,
  isDisabled,
  height,
  width,
  placeholder,
  isSearchable,
  isCentred,
  withCustomChevron,
  optionsDisplayStyle,
  menuDisplayStyle,
  menuListStyle,
  placeholderStyle,
  valueContainerStyle,
  controlStyle,
  indicatorsContainerStyle,
  singleValueStyle,
}: Props) => {
  const prefixOption = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  })

  const { Option } = components
  const IconOption = (props: any) => {
    return (
      <Option {...props}>
        {props.data.icon && (
          <SvgIcon name={props.data.icon} className="icon mr-3" />
        )}
        <span>{props.data.label}</span>
      </Option>
    )
  }
  const customStyles = {
    option: (styles: any, conf: any) => {
      return {
        ...styles,
        ...prefixOption(conf.data.color),
        color: '#030F40',
        '&:hover': {
          backgroundColor: '#F4F6FB',
        },
        fontWeight: conf.isSelected ? 'bold' : 'normal',
        backgroundColor: conf.isSelected ? '#D0D8E6' : '#fff',
        ...optionsDisplayStyle,
      }
    },
    placeholder: (styles: any) => {
      return {
        ...styles,
        ...placeholderStyle,
      }
    },
    valueContainer: (provided: any) => {
      const paddingTop = 0
      const paddingBottom = 0

      return {
        ...provided,
        height,
        paddingTop,
        paddingBottom,
        ...valueContainerStyle,
      }
    },
    control: (styles: any) => {
      return {
        ...styles,
        '&:hover': {
          borderColor: '#d0d8e6',
          boxShadow: 'none',
          outline: 0,
        },
        border: '1px solid #d0d8e6',
        borderRadius: 0,
        boxShadow: 'none',
        color: '#030F40',
        height,
        ...(width ? { width } : {}),
        ...controlStyle,
      }
    },
    indicatorSeparator: (provided: any) => {
      const backgroundColor = 'transparent'

      return { ...provided, backgroundColor }
    },
    indicatorsContainer: (provided: any, state: any) => {
      return {
        ...provided,
        ...indicatorsContainerStyle,
        svg: withCustomChevron
          ? {
              color: '#030f40',
              transform: state.selectProps.menuIsOpen
                ? 'rotate(180deg)'
                : 'rotate(0deg)',
              transition: 'transform 0.1s',
            }
          : {},
      }
    },
    input: (styles: any) => {
      return {
        ...styles,
        margin: 0,
        padding: 0,
      }
    },
    menu: (styles: any) => {
      return {
        ...styles,
        borderRadius: 0,
        zIndex: 999,
        ...menuDisplayStyle,
      }
    },
    menuList: (provided: any) => {
      return { ...provided, ...menuListStyle }
    },
    singleValue: (styles: any, { data }: any) => {
      const centerValue = isCentred
        ? { width: '100%', justifyContent: 'center' }
        : ''
      return {
        ...styles,
        ...prefixOption(data.color),
        ...singleValueStyle,
        ...centerValue,
      }
    },
  }

  return (
    <Select
      id={id}
      defaultOptions={defaultOptions}
      options={options}
      value={value}
      className={className}
      onChange={onChange}
      styles={customStyles}
      name={name}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isSearchable={isSearchable}
      components={{ Option: IconOption }}
    />
  )
}
CustomSelect.defaultProps = {
  id: '',
  defaultOptions: '',
  value: null,
  className: '',
  name: '',
  isDisabled: false,
  onChange: null,
  height: 38,
  width: '',
  placeholder: 'Choisissez une option...',
  isSearchable: true,
  isCentred: false,
  withCustomChevron: false,
  optionsDisplayStyle: {},
  menuDisplayStyle: {},
  menuListStyle: {},
  placeholderStyle: {},
  valueContainerStyle: {},
  controlStyle: {},
  indicatorsContainerStyle: {},
  singleValueStyle: {},
}

export default CustomSelect
